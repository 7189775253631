/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"), url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"), url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"), url(./assets/fonts/Poppins-SemiBold.ttf) format("truetype");
}

$primary-color: #3B325F;
$primary-color-light:#573CCB;
$primary-color-light2:#52497A;
$primary-color-light3:#AFF2F9;
$primary-color-dark:#1F1837;
$secondary-color: #E9B640;
$normal-text-color: #AAA6BC;
$foreground-color:#fff;
// $primary-color: #c35bc5;
// $primary-color-light:#c5638e;
// $primary-color-light2:#a57288;
// $primary-color-light3:#AFF2F9;
// $primary-color-dark:#4c124d;
// $secondary-color: #E9B640;
// $normal-text-color: #b394a1;
// $foreground-color:rgb(206, 139, 200);
html, body { height: 100%;font-family: 'Poppins-Regular' !important;line-height:1.6rem !important;   scroll-behavior: smooth;
}
body { margin: 0;-webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bold{ font-size:18px;  color:$primary-color;font-family: 'Poppins-Bold';}
.bold-25{ font-size:25px;  color:$primary-color;font-family: 'Poppins-Bold';}



.bold-small{font-size:13px !important; font-family:'Poppins-Bold';color:$primary-color;}
.bold-14-grey{font-size:13px !important; font-family:'Poppins-Bold';color:$normal-text-color;}

.bold-small-white{font-size:13px; font-family:'Poppins-Bold';color:$foreground-color;}

.bold-white{ font-size:18px; font-weight:800; color:$foreground-color;font-family: 'Poppins';}

.semibold-grey{ font-size:14px !important;  color:$normal-text-color !important;font-family: 'Poppins-SemiBold' !important;}
.semibold-white{ font-size:14px;  color:$foreground-color;font-family: 'Poppins-SemiBold';}
.semibold-blue{ font-size:14px;  color:$primary-color;font-family: 'Poppins-SemiBold';}
.semibold{ font-size:12px !important; color:$primary-color;font-family: 'Poppins-SemiBold';}
.semibold-13{ font-size:13px !important; color:$primary-color;font-family: 'Poppins-SemiBold';}
.semibold-20{ font-size:20px !important; color:$primary-color;font-family: 'Poppins-SemiBold';}
.semibold-13-grey{ font-size:13px !important; color:$primary-color;font-family: 'Poppins-SemiBold';}
.semibold-20-grey{ font-size:20px !important; color:$normal-text-color;font-family: 'Poppins-SemiBold';}




.regular {font-size:12px; color:$normal-text-color;font-family: 'Poppins-Regular';}
.regular-blue {font-size:12px; color:$primary-color;font-family: 'Poppins-Regular';}
.regular-14 {font-size:14px; color:$primary-color;font-family: "Poppins-Regular";}
.regular-13 {font-size:13px; color:$normal-text-color;font-family: 'Poppins-Regular';}
.regular-10-blue {font-size:10px  ; color:$primary-color;font-family: 'Poppins-Regular';}
.regular-20 {font-size:20px; color:$normal-text-color;font-family: 'Poppins-Regular';}


.light-violet-bg{background-color:$primary-color-light !important;}
.light-violet-bg2{background-color:$primary-color-light2 !important;}
.violet-bg {background-color:$primary-color-dark !important; }
.gold-bg {background-color:$secondary-color !important;}
.grey{color:$normal-text-color;}
.gold{color:$secondary-color;}

input[type="text"],input[type='number']
{font-size:13px; color:$normal-text-color;font-family: 'Poppins-Regular';}

textarea.form-control{font-size:13px; color:$normal-text-color;font-family: 'Poppins-Regular';}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $normal-text-color;
  opacity: 1; /* Firefox */
  font-family:  "Poppins-Regular";
}

select {
  -webkit-appearance: menulist-button;
  color: $normal-text-color;
  font-family:"Poppins-Bold";
  font-size:13px;
}



.custom-list{
    list-style-image:  url("/assets/icons/gold-tick.svg");
}

.card{
    background-color:$foreground-color;
    border-width:1px;
    border-radius:10px;
    border-color: $primary-color-light3;
    box-shadow: 1px 1px 1px rgba(59,50,95,0.07);
    width:100%;
    /* min-height:20vh; */
    overflow:hidden;
    overflow-y:hidden;

}

.center{
    display:flex;
    align-items:center;
    justify-content:center;
}


.card-body{
    /* padding:5vw; //desktop mview change */
    padding:5%;
    padding-top:5px;
}



.mat-select-panel {border-radius:10px;}
.custom-radius{ border-radius:10px;}
.mat-select-value {color:$normal-text-color !important;}

::ng-deep .mat-form-field-underline {
    display: none;
  }

.custom-btn{
    display:flex;
    /* width:100%; */
    border-radius:10px;
    height:8vh;
    border:none;
    justify-content:center;
    align-items:center;

}


.custom-dialog .mat-dialog-container{
/* overriding mat dialog, injeted using panel class during dialog.open */
    border-radius:10px;
    padding:2rem;
    border-radius:10px !important;
}



.custom-dialog-btn{
    display:flex;
    border-radius:10px;
    height:6vh;
    border:none;
    justify-content:center;
    align-items:center;

}


.violet-card{
    background-color:$primary-color-light;
    color:$foreground-color;
    font-weight:800;
    font-size:13px;
    border-radius:10px;
    box-shadow: 1px 1px 1px rgba(59,50,95,0.07);
    width:100%;
    min-height:5vh;
    display:flex;
    align-content:center;
    justify-content:center;
}



.check-point{
    display:flex;
    align-items:center;
}

.custom-otp{
  width:40px !important;
  height:40px !important;
  border-radius:10px;
}


// Start Input checkbox customization
/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 5px;;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #000;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: $secondary-color;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
// End Input checkbox customization

@media only screen and (orientation:portrait){
    .custom-input{
        background-color:$foreground-color;
        border:solid 1px rgba(170,166,188,38);
        border-radius:10px;
        height:7vh;
        padding-left:3vw;
    }
    .custom-textarea{
        background-color:$foreground-color;
        border:solid 1px rgba(170,166,188,38);
        border-radius:10px;
        padding-left:3vw;
    }

    .custom-select{
        background-color:$foreground-color;
        border:solid 1px rgba(170,166,188,38);
        border-radius:10px;
        height:7vh;
        padding-left:3vw;
    }

    .card-title{
        border-left:solid $secondary-color 3px;
        width:100%;
        margin-top:10px;
        padding-top:5px;
        padding-bottom:5px;
        padding-left:5vw
    }

}

/* used to control view for mobile and desktop */
@media only screen and (orientation:landscape){

        .container-view{background-color:grey;
            display:flex;
            justify-content:center;
            overflow:hidden;}

        .actual-view{height:100vh;
                    background:$foreground-color;
                    overflow:scroll;
                    width:56.25vh;}

        .phone-view-width{width:56.25vh;}

        .custom-dialog .mat-dialog-container{
            width:45vh;
            padding:2rem;
            border-radius:10px !important;
        }

        .custom-dialog{
            display:flex;
            justify-content:center;
        }

        .custom-input{
            background-color:$foreground-color;
            border:solid 1px rgba(170,166,188,38);
            border-radius:10px;
            height:7vh;
            padding-left:2vh;

        }
        .custom-textarea{
            background-color:$foreground-color;
            border:solid 1px rgba(170,166,188,38);
            border-radius:10px;
            padding-left:2vh;
        }

        .custom-select{
            background-color:$foreground-color;
            border:solid 1px rgba(170,166,188,38);
            border-radius:10px;
            height:7vh;
            padding-left:2vh;
        }

        .card-title{
            border-left:solid $secondary-color 3px;
            width:100%;
            margin-top:10px;
            padding-top:5px;
            padding-bottom:5px;
            padding-left:2.5vh;
        }

}

.error-mat-snackbar {
  background-color: #dc3545;
  color: white;

  button {
    color: white;
  }
}

.success-mat-snackbar {
  background-color: #198754;
  color: white;

  button {
    color: white;
  }
}

.warning-mat-snackbar {
  background-color: #ffc107;
  color: white;

  button {
    color: white;
  }
}

.info-mat-snackbar {
  background-color: #0dcaf0;
  color: white;

  button {
    color: white;
  }
}

 .cdk-overlay-pane{
    bottom: 0px 
} 

::-webkit-scrollbar {
  width: 6px;
}

/* Track styles */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle styles */
::-webkit-scrollbar-thumb {
  background: #e9b640;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e9b640;
}

/* Horizontal scrollbar */
::-webkit-scrollbar:horizontal {
  height: 6px;
}

/* Handle styles for horizontal scrollbar */
::-webkit-scrollbar-thumb:horizontal {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover for horizontal scrollbar */
::-webkit-scrollbar-thumb:horizontal:hover {
  background: #555;
}

    /* end of control view for mobile and desktop */






